import React, { useState, useEffect } from "react";
import { CategoryAPI } from "../../apis/CategoryAPI";
import { ShopAPI } from "../../apis/ShopAPI";
import { useNavigate } from "react-router-dom";
const AddShop = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [categories, setCategories] = useState(null);
  const [shop, setShop] = useState({
    name: "",
    image: null,
    show: false,
    id_category: null,
  });
  const [loading, setLoading] = useState(true);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const allowedFormats = ["image/jpeg", "image/png"];
    if (!allowedFormats.includes(file.type)) {
      alert("Only JPEG and PNG files are allowed.");
      return;
    }

    // Vérification de la taille de l'image (1MB maximum)
    const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
    if (file.size > maxSize) {
      alert("The image size should not exceed 1MB.");
      return;
    }
    setShop({ ...shop, image: file });
    setSelectedImage(URL.createObjectURL(file));
  };

  const handleInputShop = (e) => {
    setShop({ ...shop, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    try {
      CategoryAPI.getAll().then((categories) => {
        setCategories(categories);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const navigate = useNavigate();
  const apiCall = () => {
    const formData = new FormData();
    formData.append("name", shop.name);
    formData.append("image", shop.image);
    formData.append("show", shop.show);
    if (shop.id_category == null && categories.length > 0) {
      formData.append("id_category", categories[0].id);
    } else {
      formData.append("id_category", shop.id_category);
    }

    try {
      ShopAPI.create(formData).then((response) => {
        setShop({ name: "", image: null, show: false, id_category: null });
        setSelectedImage(null);
        navigate("/shops");
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-8 lg:py-16">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Add a new Shop
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Shop Name
                </label>
                <input
                  type="text"
                  value={shop.name}
                  onChange={handleInputShop}
                  name="name"
                  id="name"
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Type shop name"
                  required
                />
              </div>
              <div className="w-full sm:col-span-2">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 "
                  htmlFor="user_avatar"
                >
                  Upload image
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                  aria-describedby="user_avatar_help"
                  id="user_avatar"
                  onChange={handleImageUpload}
                  type="file"
                />
              </div>

              <div className="w-full sm:col-span-2">
                <div className="md:h-32 flex items-center text-center bg-gray-100 border border-gray-200 rounded-lg shadow flex-row max-w-xl hover:bg-indigo-100 ">
                  <div>
                    <img
                      className="object-cover rounded-t-lg h-32 w-36 md:w-48 rounded-l-lg "
                      src={selectedImage}
                      alt="Select Image"
                    />
                  </div>
                  <div className="mx-auto flex flex-col items-center p-4 leading-normal text-center">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">
                      {shop.name}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Show Shop
                </label>
                <label className="relative mt-2 inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={shop.show}
                    onChange={() => setShop({ ...shop, show: !shop.show })}
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>{" "}
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Select Category
                </label>
                <select
                  onChange={(event) =>
                    setShop({ ...shop, id_category: event.target.value })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="text-center">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Add shop
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
    </div>
  );
};

export default AddShop;
