import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ProductAPI = {

  getAll: async function (id,page,cancel = false) {
    const response = await api.request({
      url: `/api/admin/subcategories/${id}/items?page=${page}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },
  create: async function (product,cancel = false) {
    const response = await api.request({
      url: `/api/admin/items`,
      method: "POST",
      data: product,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/items/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },
  update: async function (product,id,cancel = false) {
    const response = await api.request({
      url: `/api/admin/items/${id}`,
      method: "POST",
      data: product,
      headers: { "Content-Type": "multipart/form-data" },
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },
  get: async function (id,cancel = false) {
    const response = await api.request({
      url: `/api/admin/items/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },

}

const cancelApiObject = defineCancelApiObject(ProductAPI)