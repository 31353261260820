
import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ShippingAPI = {

  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/api/cities",
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },

  create: async function (shipping, cancel = false) {
    const response = await api.request({
      url: `/api/admin/cities`,
      method: "POST",
      data: shipping,
      signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },

  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/cities/${id}`,
      method: "DELETE",
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },

  get: async function (id,cancel = false) {
    const response = await api.request({
      url: `/api/admin/cities/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },

  update: async function (city,id,cancel = false) {
    const response = await api.request({
      url: `/api/admin/cities/${id}`,
      method: "PUT",
      data: city,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },

}

const cancelApiObject = defineCancelApiObject(ShippingAPI)