import React, { useState } from "react";
import { ShippingAPI } from "../../apis/ShippingAPI";
import { useNavigate } from "react-router-dom";
const AddShipping = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState({ name: "", cost: 0 });

  const handleChangeInputNumber = (event) => {
    const inputValue = event.target.value;
    // Vérifier si la valeur correspond à un nombre ou un décimal valide
    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      // setInputNumber(inputValue); // Mettre à jour l'état inputNumber
      handleInputCity(event);
    }
  };

  const handleInputCity = (e) => {
    setCity({ ...city, [e.target.name]: e.target.value });
  };
  const callApi = () => {
    try {
      ShippingAPI.create(city).then(() => {
        setCity({ name: "", cost: 0 });
        navigate("/shipping");
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <div>
      <section className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-8 lg:py-16">
          <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
            Add new City
          </h2>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                City Name
              </label>
              <input
                type="text"
                value={city.name}
                onChange={handleInputCity}
                name="name"
                id="name"
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Type city name"
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Cost
              </label>
              <input
                type="number"
                value={city.cost}
                onChange={handleChangeInputNumber}
                step="0.1"
                pattern="[0-9]+([.][0-9]+)?"
                inputMode="decimal"
                name="cost"
                id="name"
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Type shipping cost"
                required
              />
            </div>

            <div className="text-center">
              <button
                onClick={callApi}
                type="submit"
                className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
              >
                Add city
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddShipping;
