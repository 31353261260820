import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import PrivateRoutes from "./route/PrivateRoutes";
import Login from "./pages/auth/Login";
import Categories from "./pages/categories/Categories";
import Layout from "./components/Layout";
import AddCategory from "./pages/categories/AddCategory";
import EditCategory from "./pages/categories/EditCategory";
import Shops from "./pages/shops/Shops";
import AddShop from "./pages/shops/AddShop";
import EditShop from "./pages/shops/EditShop";
import Products from "./pages/products/Products";
import Logout from "./components/Logout";
import AddProduct from "./pages/products/AddProduct";
import Shipping from "./pages/shippings/Shipping";
import EditProduct from "./pages/products/EditProduct";
import AddShipping from "./pages/shippings/AddShipping";
import EditShipping from "./pages/shippings/EditShipping";
import Sections from "./pages/sections/Sections";
import AddSection from "./pages/sections/AddSection";
import EditSection from "./pages/sections/EditSection";
import "./App.css";

function App() {
  useEffect(() => {
    document.title = "Admin Dashboard";
  });
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Categories />} />
              <Route path="edit_category/:id" element={<EditCategory />} />
              <Route path="add_category" element={<AddCategory />} />
              <Route path="shops" element={<Shops />} />
              <Route path="edit_shop/:id" element={<EditShop />} />
              <Route path="add_shop" element={<AddShop />} />
              <Route path="products" element={<Products />} />
              <Route path="edit_product/:id" element={<EditProduct />} />
              <Route path="add_product" element={<AddProduct />} />
              <Route path="shipping" element={<Shipping />} />
              <Route path="add_shipping" element={<AddShipping />} />
              <Route path="edit_shipping/:id" element={<EditShipping />} />
              <Route path="sections" element={<Sections />} />
              <Route path="add_section" element={<AddSection />} />
              <Route path="edit_section/:id" element={<EditSection />} />
              <Route path="logout" element={<Logout />} />
              {/* <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
