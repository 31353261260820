import React, { useState, useEffect } from "react";
import { shops } from "../../components/data";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import SectionTableRow from "../../components/SectionTableRow";
import { ProductAPI } from "../../apis/ProductAPI";
import { ShopAPI } from "../../apis/ShopAPI";
import { SectionAPI } from "../../apis/SectionAPI";
const Sections = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idSectionDelete, setIdSection] = useState(null);
  const [shops, setShops] = useState([{ name: "All" }]);
  const [sections, setSection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current_page: null,
    last_page: null,
    per_page: null,
    total: null,
  });
  const [parameters, setParameter] = useState({
    subcategory_id: null,
    page: 1,
  });
  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdSection(id);
  };

  useEffect(() => {
    try {
      ShopAPI.getAll().then((shopsApi) => {
        setShops([...shops, ...shopsApi]);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      SectionAPI.getAll(parameters.page).then((sectionApi) => {
        setSection(sectionApi.data);
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, []);

  const handleDropDownInput = (event) => {
    setParameter({
      ...parameters,
      subcategory_id: event.target.value,
    });
  };

  useEffect(() => {
    try {
      SectionAPI.getAll(parameters.page, parameters).then((sectionsApi) => {
        setSection(sectionsApi.data);
        setPagination({
          current_page: sectionsApi.current_page,
          total: sectionsApi.total,
          last_page: sectionsApi.last_page,
          per_page: sectionsApi.per_page,
        });
      });
    } catch (error) {
      console.log(error);
    }
  }, [parameters]);

  const next = () => {
    if (pagination.current_page < pagination.last_page) {
      setParameter({
        ...parameters,
        page: parameters.page + 1,
      });
    }
  };
  const previous = () => {
    if (pagination.current_page > 1) {
      setParameter({
        ...parameters,
        page: parameters.page - 1,
      });
    }
  };
  const deleteApiCall = () => {
    try {
      SectionAPI.delete(idSectionDelete).then(() => {
        const updatedData = sections.filter(
          (item) => item.id !== idSectionDelete
        );
        setSection(updatedData);
        setShowModalDelete(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div>
          <div className="mt-20 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg ">
            <div className="flex justify-between">
              <div className="relative w-full lg:max-w-sm">
                <select
                  onChange={(event) => handleDropDownInput(event)}
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {shops &&
                    shops.length > 0 &&
                    shops.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="flex justify-center md:justify-end mb-5">
                <Link to="/add_section">
                  <Button gradientMonochrome="purple">+ ADD Section</Button>
                </Link>
              </div>
            </div>

            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-3 py-3"></th>
                  <th scope="col" className="px-3 py-3">
                    Section name
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Shop
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {sections.map((section) => (
                  <SectionTableRow
                    key={section.id}
                    section={section}
                    deleteModal={openDeleteModal}
                  />
                ))}
              </tbody>
            </table>

            {showModalDelete ? (
              <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      {/*header*/}
                      <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-3xl font-semibold">Warning</h3>
                        <button
                          className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          onClick={() => setShowModalDelete(false)}
                        >
                          <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                            ×
                          </span>
                        </button>
                      </div>
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                          Are you certain that you wish to delete this product?{" "}
                          <br />
                        </p>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                          className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModalDelete(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={deleteApiCall}
                        >
                          DELETE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
              </>
            ) : null}
          </div>

          <div className="flex justify-center mt-5 mb-8">
            <nav aria-label="Page navigation example">
              <ul className="inline-flex -space-x-px">
                {pagination.last_page && pagination.current_page > 1 ? (
                  <li>
                    <div
                      onClick={previous}
                      className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-lime-500 border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Previous
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                      Previous
                    </div>
                  </li>
                )}
                <li>
                  <div className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                    {parameters.page}
                  </div>
                </li>
                {pagination.last_page &&
                pagination.last_page > pagination.current_page ? (
                  <li>
                    <div
                      onClick={next}
                      className="px-3 py-2 leading-tight text-black bg-lime-500 border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      Next
                    </div>
                  </li>
                ) : (
                  <li>
                    <div className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                      Next
                    </div>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sections;
