import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryAPI } from "../../apis/CategoryAPI";
import { API_URL_IMAGE_CATEGORY } from "../../apis/URL/constants";
import { Navigate } from "react-router-dom";
const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [category, setCategory] = useState({ name: "", image: null });
  const [loading, setLoading] = useState(true);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const allowedFormats = ["image/jpeg", "image/png"];
    if (!allowedFormats.includes(file.type)) {
      alert("Only JPEG and PNG files are allowed.");
      return;
    }

    // Vérification de la taille de l'image (1MB maximum)
    const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
    if (file.size > maxSize) {
      alert("The image size should not exceed 1MB.");
      return;
    }

    setSelectedImage(URL.createObjectURL(file));
    setCategory({ ...category, image: file });
  };

  const handleInputCategory = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    try {
      CategoryAPI.get(id).then((category) => {
        setCategory({ name: category.name, image: null });
        setSelectedImage(API_URL_IMAGE_CATEGORY + category.image);
        setLoading(false);
      });
    } catch (error) {
      console.log(error.response.data);
    }
  }, []);

  const apiCall = () => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", category.name);
    if (category.image != null) {
      formData.append("image", category.image);
    }

    try {
      CategoryAPI.update(formData, id).then(() => {
        navigate("/");
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="bg-white">
          <div className="mx-auto max-w-2xl px-4 py-8 lg:py-16">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Edit Category
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Category Name
                </label>
                <input
                  type="text"
                  value={category.name}
                  name="name"
                  id="name"
                  onChange={handleInputCategory}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Type category name"
                  required
                />
              </div>
              <div className="w-full sm:col-span-2">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 "
                  htmlFor="user_avatar"
                >
                  Upload image
                </label>
                <input
                  name="image"
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                  aria-describedby="user_avatar_help"
                  id="image"
                  onChange={handleImageUpload}
                  type="file"
                />
              </div>

              <div className="w-full sm:col-span-2 ">
                <div className="mx-auto  py-5 px-4 w-full max-w-md sm:max-w-2xl ">
                  <div className="grid  grid-cols-2 gap-5 ">
                    <div className="h-72 order-5 lg:row-span-2 col-span-full sm:col-span-1 relative shadow rounded-md overflow-hidden bg-blue-800 filter hover:shadow-lg hover:brightness-125">
                      <div>
                        <img
                          src={selectedImage}
                          alt="Select image"
                          class="absolute inset-0 w-full h-full object-cover object-center"
                        />

                        <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-700 opacity-50" />
                      </div>
                      <div className="relative h-full flex flex-col justify-between items-start space-y-10 text-white">
                        {/* :::badge tag */}
                        {/* <span className="inline-flex justify-center items-center py-1 px-3 border-none rounded bg-white bg-opacity-30 text-xs text-white font-semibold">Popular</span> */}
                        {/* :::name */}
                        <h3 className="text-3xl pl-5 font-playfair tracking-wider leading-relaxed antialiased">
                          <span className="block">{category.name}</span>
                          {/* <span className="block">Collection</span> */}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Edit category
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default EditCategory;
