import React, { useState, useEffect } from "react";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import ShippingTableRow from "../../components/ShippingTableRow";
import { ShippingAPI } from "../../apis/ShippingAPI";
const Shipping = () => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idShippingDelete, setIdShipping] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shipping, setShipping] = useState([]);

  const openDeleteModal = (show, id) => {
    setShowModalDelete(show);
    setIdShipping(id);
  };

  useEffect(() => {
    try {
      ShippingAPI.getAll().then((shipping) => {
        setShipping(shipping);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteApiCall = () => {
    try {
      ShippingAPI.delete(idShippingDelete).then(() => {
        const updatedData = shipping.filter(
          (item) => item.id !== idShippingDelete
        );
        setShipping(updatedData);
        setShowModalDelete(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="mt-20 md:ml-10 md:mr-10 relative overflow-x-auto shadow-md sm:rounded-lg ">
          <div className="flex justify-center md:justify-end mb-5">
            <Link to="/add_shipping">
              <Button gradientMonochrome="purple">+ ADD CITY</Button>
            </Link>
          </div>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  City name
                </th>
                <th scope="col" className="px-6 py-3">
                  Cost
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {shipping.map((shipping) => (
                <ShippingTableRow
                  key={shipping.id}
                  shipping={shipping}
                  deleteModal={openDeleteModal}
                />
              ))}
            </tbody>
          </table>

          {showModalDelete ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 className="text-3xl font-semibold">Warning</h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModalDelete(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto">
                      <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        Are you certain that you wish to delete this city?{" "}
                        <br />
                      </p>
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModalDelete(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => deleteApiCall()}
                      >
                        DELETE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Shipping;
