import React from "react"
import { Outlet } from "react-router-dom";
import Sidebar from "./SideBar";

const Layout = () => {
    return (
      <div>
        
    <div className="flex ">
      <div className="flex flex-col h-screen">
        <Sidebar />
      </div>
      <div className="flex-1 h-screen overflow-y-auto">
      <Outlet></Outlet>
      </div>
    </div>

      </div>
    );
  };
  
  export default Layout;