import React, { useState, useEffect } from "react";
import { SectionAPI } from "../../apis/SectionAPI";
import { ShopAPI } from "../../apis/ShopAPI";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert";
const AddSection = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [section, setSection] = useState({
    name: "",
    subcategory_id: null,
  });
  const [shops, setShop] = useState(null);

  const [loading, setLoading] = useState(true);

  const handleInputSection = (e) => {
    setSection({ ...section, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    try {
      ShopAPI.getAll().then((shopsApi) => {
        setShop(shopsApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const apiCall = async () => {
    if (section.subcategory_id == null && shops.length > 0) {
      section.subcategory_id = shops[0].id;
    }
    try {
      await SectionAPI.create(section).then((response) => {
        setSection({ name: "" });
        navigate("/sections");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
      // console.log(error.response.data.message);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="mt-20 relative">
          <ErrorAlert show={showError} error={error} />
          <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Add new Section
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Section Name
                </label>
                <input
                  type="text"
                  value={section.name}
                  name="name"
                  id="name"
                  onChange={handleInputSection}
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Input section name"
                  required
                />
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Select Shop
                </label>
                <select
                  onChange={(event) =>
                    setSection({
                      ...section,
                      subcategory_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {shops &&
                    shops.length > 0 &&
                    shops.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="w-full sm:col-span-2"></div>

              <div className="text-center">
                <button
                  type="submit"
                  onClick={apiCall}
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Add section
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
    </div>
  );
};

export default AddSection;
