import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const SectionAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `/api/admin/sections?page=${page}`;

    if (
      params.subcategory_id !== undefined &&
      params.subcategory_id !== null &&
      params.subcategory_id !== "All"
    ) {
      url += `&subcategory_id=${params.subcategory_id}`;
    }

    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  create: async function (section, cancel = false) {
    const response = await api.request({
      url: `/api/admin/sections`,
      method: "POST",
      data: section,
      signal: cancel
        ? cancelApiObject[this.create.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  delete: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/sections/${id}`,
      method: "DELETE",
      signal: cancel
        ? cancelApiObject[this.delete.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/sections/${id}`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getByShop: async function (id, cancel = false) {
    const response = await api.request({
      url: `/api/sections/${id}/subcategory`,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  update: async function (category, id, cancel = false) {
    const response = await api.request({
      url: `/api/admin/sections/${id}`,
      method: "PUT",
      data: category,
      signal: cancel
        ? cancelApiObject[this.update.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(SectionAPI);
