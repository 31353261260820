import axios from "axios";
import { API_URL } from "../URL/constants";

export const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  xsrfHeaderName: "X-XSRF-TOKEN",
});

// defining a custom error handler for all APIs
const errorHandler = (error) => {
  const statusCode = error.response?.status;

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    const firstErrorKey = Object.keys(error.response.data.error)[0];
    const errorMessage = error.response.data.error[firstErrorKey][0];
    console.error(errorMessage);
    console.error(error.response.data);
    if (errorMessage) {
      alert(errorMessage);
    } else {
      alert(error.response.data.message);
    }
  }
  if (statusCode == 401) {
    // navigate('/login');
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

api.interceptors.request.use((config) => {
  // Récupérez le token JWT depuis le SessionStorage
  const token = sessionStorage.getItem("token");

  // Ajoutez le token en tant qu'en-tête d'autorisation
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
