
import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AuthAPI = {

  csrfToken:async function (cancel = false) {
    const response = await api.request({
      url: `/sanctum/csrf-cookie`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.csrfToken.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },

  login: async function (email,password, cancel = false) {
    const response = await api.request({
      url: `/api/auth/adminlogin`,
      method: "POST",
      data: {
        email: email,
        password: password
      },
      signal: cancel ? cancelApiObject[this.login.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },
  logout: async function (cancel = false) {
    const response = await api.request({
      url: `/api/auth/logout`,
      method: "POST",
      signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },

}

const cancelApiObject = defineCancelApiObject(AuthAPI)