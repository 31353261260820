import React from "react";
import { useState, useEffect } from "react";
import control from "../assets/logo/control.png";
import user from "../assets/logo/user.png";
import { Link } from "react-router-dom";
import categoryLogo from "../assets/logo/category-svgrepo-com.svg";
import shopLogo from "../assets/logo/small-shop-alt-svgrepo-com.svg";
import productLogo from "../assets/logo/product-svgrepo-com.svg";
import logoutLogo from "../assets/logo/logout-svgrepo-com.svg";
import deliveryLogo from "../assets/logo/delivery-cost-truck-svgrepo-com.svg";
import sectionLogo from "../assets/logo/section-svgrepo-com.svg";
const SideBar = () => {
  const [open, setOpen] = useState(true);
  const Menus = [
    { title: "Categories", src: categoryLogo, route: "/" },
    { title: "Shops", src: shopLogo, route: "/shops" },
    { title: "Products", src: productLogo, route: "/products" },
    { title: "Sections", src: sectionLogo, gap: true, route: "/sections" },
    { title: "Shipping", src: deliveryLogo, route: "/shipping" },
    { title: "Log out", src: logoutLogo, gap: true, route: "/logout" },
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        // Par exemple, vous pouvez ajuster cette valeur selon vos besoins
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Appel initial pour définir l'état initial

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex bg-dark-purple">
      <div
        className={` ${
          open ? "w-72" : "w-20 "
        } bg-dark-purple h-screen p-5  pt-8 relative duration-300`}
      >
        <img
          src={control}
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple
             border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src={user}
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            Administrator
          </h1>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <Link to={Menu.route}>
              <li
                key={index}
                className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
                ${Menu.gap ? "mt-9" : "mt-2"} ${index === 0} `}
              >
                <img src={Menu.src} />

                <span
                  className={`${!open && "hidden"} origin-left duration-200`}
                >
                  {Menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
