import React, { useEffect, useState } from "react";
import { ShopAPI } from "../../apis/ShopAPI";
import { ProductAPI } from "../../apis/ProductAPI";
import SuccessAlert from "../../components/SuccessAlert";
import { SectionAPI } from "../../apis/SectionAPI";
const AddProduct = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState([]);
  const [sections, setSection] = useState([{ name: "none" }]);
  const [product, setProduct] = useState({
    name: "",
    image: null,
    show: false,
    id_subcategory: null,
    description: "",
    price: 0,
    section_id: null,
  });
  const [shops, setShops] = useState([]);

  useEffect(() => {
    let timeoutId;
    if (showAlert) {
      timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAlert]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const allowedFormats = ["image/jpeg", "image/png"];
    if (!allowedFormats.includes(file.type)) {
      alert("Only JPEG and PNG files are allowed.");
      return;
    }

    // Vérification de la taille de l'image (1MB maximum)
    const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
    if (file.size > maxSize) {
      alert("The image size should not exceed 1MB.");
      return;
    }
    setSelectedImage(URL.createObjectURL(file));
    setProduct({ ...product, image: file });
  };

  const handleInputProduct = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleChangeInputNumber = (event) => {
    const inputValue = event.target.value;

    // Vérifier si la valeur correspond à un nombre ou un décimal valide
    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      setProduct({ ...product, price: inputValue }); // Mettre à jour l'état inputNumber
    }
  };

  useEffect(() => {
    try {
      ShopAPI.getAll().then((shops) => {
        setShops(shops);
        if (shops.length > 0) {
          fetchSection(shops[0].id);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (
      product.id_subcategory !== null &&
      product.id_subcategory !== undefined
    ) {
      fetchSection(product.id_subcategory);
    }
  }, [product.id_subcategory]);

  const fetchSection = (shopId) => {
    try {
      SectionAPI.getByShop(shopId).then((sectionApi) => {
        setSection([{ name: "none" }, ...sectionApi.data]);
        setProduct({
          ...product,
          section_id: null,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeSection = (newSectionId) => {
    setProduct({
      ...product,
      section_id: newSectionId,
    });
  };
  const apiCall = () => {
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("image", product.image);
    formData.append("show", product.show);
    formData.append("description", product.description);
    formData.append("price", product.price);
    if (product.id_subcategory == null && shops.length > 0) {
      formData.append("id_subcategory", shops[0].id);
    } else {
      formData.append("id_subcategory", product.id_subcategory);
    }
    if (product.section_id === "none" || product.section_id === null) {
      formData.append("section_id", "");
    } else {
      formData.append("section_id", product.section_id);
    }

    try {
      ProductAPI.create(formData).then((response) => {
        setProduct({
          name: "",
          image: null,
          show: false,
          id_subcategory: null,
          description: "",
          price: 0,
          section_id: null,
        });
        setSelectedImage(null);
        setMessage("Product added successfully");
        setShowAlert(true);
        // navigate('/')
      });
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <div>
      <div className="  relative mx-auto max-w-2xl px-4 justify-center">
        <SuccessAlert show={showAlert} message={message} />
      </div>
      <section className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-8 lg:py-16">
          <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
            Add a new Product
          </h2>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Product Name
              </label>
              <input
                type="text"
                onChange={handleInputProduct}
                value={product.name}
                name="name"
                id="name"
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Type product name"
                required
              />
            </div>
            <div className="w-full sm:col-span-2">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 "
                htmlFor="user_avatar"
              >
                Upload image
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none "
                aria-describedby="user_avatar_help"
                id="user_avatar"
                onChange={handleImageUpload}
                type="file"
              />
            </div>

            <div className="w-full">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="user_avatar"
              >
                Show Product
              </label>
              <label className="relative mt-2 inline-flex items-center mb-4 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={product.show}
                  onChange={() =>
                    setProduct({ ...product, show: !product.show })
                  }
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>{" "}
            </div>

            <div className="relative w-full lg:max-w-sm">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="user_avatar"
              >
                Select Shop
              </label>
              <select
                onChange={(event) =>
                  setProduct({ ...product, id_subcategory: event.target.value })
                }
                className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
              >
                {shops &&
                  shops.length > 0 &&
                  shops.map((shop) => (
                    <option key={shop.id} value={shop.id}>
                      {shop.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-full">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Price
              </label>
              <input
                type="number"
                onChange={handleChangeInputNumber}
                value={product.price}
                step="0.1"
                pattern="[0-9]+([.][0-9]+)?"
                inputMode="decimal"
                name="name"
                id="name"
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                placeholder="Type product price"
                required
              />
            </div>

            <div className="relative w-full lg:max-w-sm">
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="user_avatar"
              >
                Section
              </label>
              <select
                value={product.section_id}
                onChange={(event) => handleChangeSection(event.target.value)}
                className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
              >
                {sections &&
                  sections.length > 0 &&
                  sections.map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-full sm:col-span-2">
              <textarea
                onChange={handleInputProduct}
                value={product.description}
                name="description"
                id="description"
                className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                placeholder="Type product description"
                required
              />
            </div>

            <div className="text-center w-full sm:col-span-2">
              <button
                onClick={apiCall}
                type="submit"
                className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
              >
                Add product
              </button>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center mb-5">
        <div className="w-1/2 sm:w-1/6 sm:col-span-2 ">
          <article className="relative flex flex-col overflow-hidden rounded-lg border">
            <div className="aspect-square overflow-hidden">
              <img
                className="h-full w-full object-cover transition-all duration-300 group-hover:scale-125"
                src={selectedImage}
                alt=""
              />
            </div>
            <div className="my-4 mx-auto flex w-10/12 flex-col items-start justify-between">
              <div className="mb-2 flex">
                <p className="mr-3 text-sm font-semibold">${product.price}</p>
              </div>
              <h3 className="mb-2 text-sm text-gray-400">{product.name}</h3>
            </div>

            <button className="group mx-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600">
              <div className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-[#4353de] group-hover:text-white">
                Add
              </div>
              <div className="flex items-center justify-center bg-gray-200 px-5 transition group-hover:bg-[#6875f5] group-hover:text-white">
                +
              </div>
            </button>
          </article>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
